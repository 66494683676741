.App {
  background-color: #282c34;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  font-size: 1rem;
  color: white;
}

main {
  width: 90vw;
}

#quote-box {
  /* background-color: green; */
  display: grid;
  color: white;
  width: 100%;
}

#text {
  justify-self: center;
  margin-left: 1.5rem;
  font-size: 2rem;
  max-width: 60ch;
}

#text::before,
#text::after {
  content: ' " ';
}

#author {
  justify-self: end;
  font-size: 1.5rem;
  margin-right: 2rem;
}

#author::before {
  content: "- ";
}

#new-quote {
  justify-self: center;
  width: fit-content;
  padding: 1rem 3rem;
  color: white;
  font-size: 1.25rem;
  background-color: #3b4558;
  border: none;
  border-radius: 18px;
}

#new-quote:hover {
  background-color: #495e85;
}

#tweet-quote {
  justify-self: center;
  margin-top: 1.5rem;
  text-decoration: none;
  color: white;
  background-color: #313846;
  padding: 0.5rem 3rem;
  border-radius: 18px;
}

#tweet-quote:hover {
  background-color: #3b4863;
}

#tweet-quote>img {
  width: 1.5rem;
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
}

#github-link {
  text-decoration: none;
}

#github-link>img {
  width: 2rem;
}